<template>
  <div>
    <portal to="subheader_portal">
      <router-link :to="{ name: 'logistics_dispatch_create' }">
        <b-button variant="success"
          ><b-icon icon="plus" aria-hidden="true"></b-icon> Nuevo
          envío</b-button
        >
      </router-link>
    </portal>
    <div class="row">
      <div class="col-md-12">
        <b-card class="mb-3">
          <b-card-text>
            <div class="row">
              <b-form-group class="col-md-6" label="Fecha de inicio">
                <b-form-input
                  type="date"
                  class="form-control"
                  v-model="form.start_date"
                ></b-form-input>
              </b-form-group>
              <b-form-group class="col-md-6" label="Fecha final">
                <b-form-input
                  type="date"
                  class="form-control"
                  v-model="form.end_date"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
                <b-button variant="primary" class="mr-2" @click="search">
                  Buscar
                </b-button>
                <b-button variant="primary" class="mr-2" @click="generateExcel">
                  Descargar reporte
                </b-button>
              </div>
            </div>
          </b-card-text>
        </b-card>
        <b-card>
          <b-card-text>
            <div class="table-responsive">
              <table class="table table-borderless table-vertical-center">
                <thead>
                  <tr>
                    <th class="p-0" style="width: 50px"></th>
                    <th class="p-0" style="min-width: 200px"></th>
                    <th class="p-0" style="min-width: 100px"></th>
                    <th class="p-0" style="min-width: 125px"></th>
                    <th class="p-0" style="min-width: 110px"></th>
                    <th class="p-0" style="min-width: 150px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td class="pl-0 py-4">
                      <div class="symbol symbol-50 symbol-light mr-1">
                        <span class="symbol-label"
                          ><img
                            src="media/misc/invoice.png"
                            alt=""
                            class="h-50 align-self-center"
                        /></span>
                      </div>
                    </td>
                    <td class="pl-0">
                      <a
                        class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        {{ item.serie }} - {{ item.number }}
                      </a>
                      <div>
                        <span class="font-weight-bolder">Conductor:</span
                        ><a
                          class="text-muted font-weight-bold text-hover-primary"
                        >
                          {{ item.driver_name }}
                        </a>
                      </div>
                    </td>
                    <td class="text-right">
                      <span
                        v-if="item.third_name !== null"
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >Tercero: {{ item.third_name }}
                      </span>
                    </td>
                    <td class="text-right">
                      <span class="text-muted font-weight-bold">{{
                        item.created_at
                      }}</span>
                    </td>
                    <td class="text-right">
                      <!-- <span
                        class="label label-lg label-inline label-light-success"
                      >
                        COMPROBADO
                      </span> -->
                    </td>
                    <td class="text-right pr-0"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "LogisticsDispatchList",
  data() {
    return {
      invoice: null,
      form: {
        start_date: null,
        end_date: null,
      },
      page: 1,
      rows: 0,
      perPage: 10,
      fields: [
        { key: "figure", label: "" },
        { key: "serie", label: "Serie" },
        { key: "number", label: "Número" },
        { key: "customer", label: "Cliente" },
        { key: "delivery_status", label: "Delivery" },
        { key: "total", label: "Total" },
        { key: "actions", label: "acciones" },
      ],
      items: [],
    };
  },
  methods: {
    generateExcel() {
      const api_url = localStorage.getItem("API_URL");
      const url = `https://${api_url}/logistics/dispatch/export/excel/?start_date=${this.form.start_date}&end_date=${this.form.end_date}`;
      window.open(url, "_blank");
    },
    search() {
      this.getItems(1, this.form.start_date, this.form.end_date);
    },
    getItems(page = 1, start_date = null, end_date = null) {
      let slug = `?page=${page}&page_size=${this.perPage}`;
      if (start_date !== null) {
        slug = slug + `&start_date=${start_date}&end_date=${end_date}`;
      }
      ApiService.get("logistics/dispatch", slug).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.count;
      });
    },
    handlePageChange(value) {
      this.page = value;
      this.getItems(value);
    },
    showModalCreateDespatch(invoiceId) {
      localStorage.invoice = invoiceId;
      this.$bvModal.show("modal__create__despatch");
    },
  },
  mounted() {
    this.getItems();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Envíos" }]);
    const now = new Date().toISOString().slice(0, 10);
    this.form.start_date = now;
    this.form.end_date = now;
  },
};
</script>
